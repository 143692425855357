import * as React from "react";
import { observer } from "mobx-react";
import { EGDSCard, EGDSCardLink, EGDSCardContentSection } from "@egds/react-core/cards";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { useLocalization } from "@shared-ui/localization-context";
import { OfferItem } from "../../types";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

export interface FlightInventoryCardProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  border?: boolean;
}

export const FlightInventoryCard = observer(
  ({ offer, cardIndex, campaignId, moduleName, border }: FlightInventoryCardProps) => {
    if (offer.__typename !== "FlightOfferItem") {
      return null;
    }

    const { formatText } = useLocalization();

    return (
      <EGDSCard className="FlightInventoryCard" border={border}>
        <div className="uitk-card-content">
          <EGDSCardContentSection>
            <div>
              <EGDSSpacing margin={{ inlineend: "one" }}>
                <img className="airlinesIcon" src={offer.airlineImage?.url} alt={offer.airlineImage?.description} />
              </EGDSSpacing>
              <EGDSText inline size={200}>
                {offer.airlineName}
              </EGDSText>
            </div>
            <EGDSSpacing padding={{ blockend: "one", inlineend: "one" }}>
              <EGDSText size={400} overflow="truncate">
                <div
                  className="flightOfferName"
                  dangerouslySetInnerHTML={{
                    __html: formatText(
                      "merchCampaign.flightOffer.name",
                      offer.originCity,
                      offer.originAirportCode,
                      offer.destinationCity,
                      offer.destinationAirportCode
                    ),
                  }}
                />
              </EGDSText>
            </EGDSSpacing>
            <EGDSText size={300}>{offer.description}</EGDSText>
          </EGDSCardContentSection>
          <EGDSCardContentSection border="top">
            <EGDSLayoutFlex justifyContent="space-between">
              <>
                <EGDSText size={200}>{offer.dates}</EGDSText>
                {offer.flightOffer?.price.value && (
                  <div>
                    <EGDSSpacing padding={{ inlineend: "one" }}>
                      <EGDSText size={100} inline align="right" className="price-prefix">
                        {offer.flightOffer.price.prefixText}
                      </EGDSText>
                    </EGDSSpacing>
                    <EGDSText inline size={600} weight="medium" align="right" theme="emphasis">
                      {offer.flightOffer.price.value}
                    </EGDSText>
                    <EGDSText size={100} align="right">
                      {offer.flightOffer.price.qualifierText}
                    </EGDSText>
                  </div>
                )}
              </>
            </EGDSLayoutFlex>
          </EGDSCardContentSection>
          <EGDSCardLink>
            <TrackedLink
              moduleName={moduleName}
              action={Action.CLICK}
              href={offer.flightOffer?.actionLink.href}
              rfrr={`card-${campaignId}-${cardIndex}`}
            >
              {offer.flightOffer?.actionLink.text}
            </TrackedLink>
          </EGDSCardLink>
        </div>
      </EGDSCard>
    );
  }
);
