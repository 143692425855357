import * as React from "react";
import { observer } from "mobx-react";
import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSImage, EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { destinationOfferItem } from "../../Common/MerchCampaignCard/MerchCampaignCard";
import { OfferItem } from "../../types";
import { EGDSHeading, EGDSParagraph } from "@egds/react-core/text";

export interface VRCardProps {
  offer: OfferItem;
  productType: string;
  cardIndex: number;
  campaignId: string;
  displayPropertyCount?: boolean;
  moduleName: string;
}

export const VRCard = observer(
  ({ offer, productType, cardIndex, campaignId, moduleName, displayPropertyCount }: VRCardProps) => {
    if (offer.__typename !== "DestinationOfferItem") {
      return null;
    }

    const offerItem = destinationOfferItem(productType, offer);

    if (offerItem === null || offerItem === undefined) {
      return null;
    }

    return (
      <>
        <EGDSCard className="VRCard">
          <EGDSCardContentSection padded={false}>
            <EGDSFigure ratio={EGDSFigureAspectRatioType.R16_9} className="uitk-card-roundcorner-all">
              <EGDSImage src={offer.image.url} alt={offer.image.description} />
            </EGDSFigure>
            <EGDSCardLink>
              <TrackedLink
                moduleName={moduleName}
                action={Action.CLICK}
                href={offerItem?.actionLink.href}
                rfrr={`card-${campaignId}-${cardIndex}`}
              >
                {offer.name}
              </TrackedLink>
            </EGDSCardLink>
          </EGDSCardContentSection>
          <EGDSSpacing padding={{ blockstart: "one" }}>
            <div className="uitk-card-content">
              <EGDSHeading tag="h3" size={6}>
                {offer.name}
              </EGDSHeading>
              {displayPropertyCount && (offer as any).propertyCount && (
                <EGDSParagraph>{(offer as any).propertyCount}</EGDSParagraph>
              )}
            </div>
          </EGDSSpacing>
        </EGDSCard>
      </>
    );
  }
);
