import * as React from "react";
import { observer } from "mobx-react";

import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";

import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutConditionalGridTrack,
  EGDSLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";
import { EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSScrimType } from "@egds/react-core/scrim";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";
import { EGDSText, EGDSHeading } from "@egds/react-core/text";

import { ScrimCard } from "components/shared/ScrimCard/ScrimCard";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

import { useLocalization } from "@shared-ui/localization-context";
import { EGDSLink } from "@egds/react-core/link";

import { CampaignDetail } from "../../types";

export interface CampaignVRProps {
  campaign: CampaignDetail;
  campaignTitle?: string;
  imgSrc?: string;
  context?: ExtendedContextStore;
  seeMoreLinkUrl?: string;
}

export const CampaignVR = observer(({ campaign, campaignTitle, imgSrc, seeMoreLinkUrl }: CampaignVRProps) => {
  const campaignHeading = campaignTitle || campaign.headline;
  const image = imgSrc || "";
  const seeMoreUrl = seeMoreLinkUrl || "";
  const locSeeMoreKey = "propertyfilter.seeMore";
  const { formatText } = useLocalization();

  const scrimCardPropsSmall = {
    id: "vr-campaign-header",
    dataTestId: "vr-campaign-header",
    className: "",
    titleSlot: (
      <EGDSHeading tag="h3" size={3}>
        {campaignHeading}
      </EGDSHeading>
    ),
    subtitleSlot: (
      <EGDSLayoutFlex direction="column" justifyContent="space-between" style={{ height: "inherit" }}>
        <EGDSSpacing padding={{ small: { blockstart: "unset" } }}>
          <EGDSLayoutFlexItem>
            <div>
              <EGDSText size={500} theme="inverse">
                {campaign.description.long}
              </EGDSText>
            </div>
          </EGDSLayoutFlexItem>
        </EGDSSpacing>
      </EGDSLayoutFlex>
    ),
  };

  const mediumClassName = "vrScrim";
  const mediumSpacing: EGDSSpacingProps = {
    padding: { blockend: "unset" },
  };
  const titleGrid: EGDSLayoutConditionalGridTrack = { medium: 12 };
  const titleColSpan: EGDSLayoutConditionalGridSpan = { medium: 7, large: 3 };

  const scrimCardPropsMedium = {
    id: "vr-campaign-header",
    dataTestId: "vr-campaign-header",
    className: mediumClassName,
    titleSlot: (
      <EGDSLayoutGrid columns={titleGrid} space="two">
        <EGDSLayoutGridItem colSpan={titleColSpan}>
          <EGDSSpacing padding={{ inlinestart: "six" }}>
            <div>
              <Viewport>
                <ViewMedium>
                  <EGDSHeading tag="h3" size={3}>
                    {campaignHeading}
                  </EGDSHeading>
                </ViewMedium>
                <ViewLarge>
                  <EGDSHeading tag="h3" size={2}>
                    {campaignHeading}
                  </EGDSHeading>
                </ViewLarge>
              </Viewport>
            </div>
          </EGDSSpacing>
        </EGDSLayoutGridItem>
      </EGDSLayoutGrid>
    ),
    footerSlot: (
      <EGDSCard className="vrFooter">
        <EGDSCardContentSection>
          <EGDSSpacing padding={{ inlinestart: "three", block: "three" }}>
            <EGDSLayoutFlex>
              <EGDSText size={300}>
                {campaign.description.long}
                {seeMoreUrl !== "" && (
                  <EGDSLink inline>
                    <a href={seeMoreUrl} target="_blank" rel="noopener noreferrer">{` ${formatText(locSeeMoreKey)}`}</a>
                  </EGDSLink>
                )}
              </EGDSText>
            </EGDSLayoutFlex>
          </EGDSSpacing>
        </EGDSCardContentSection>
      </EGDSCard>
    ),
    spacing: mediumSpacing,
  };

  return (
    <Viewport>
      <ViewSmall>
        <ScrimCard
          {...scrimCardPropsSmall}
          aspectRatio={EGDSFigureAspectRatioType.R1_1}
          backgroundImageURL={image}
          scrimType={EGDSScrimType.OVERLAY}
        />
      </ViewSmall>
      <ViewMedium>
        <ScrimCard
          {...scrimCardPropsMedium}
          aspectRatio={EGDSFigureAspectRatioType.R4_1}
          backgroundImageURL={image}
          scrimType={EGDSScrimType.OVERLAY}
        />
      </ViewMedium>
    </Viewport>
  );
});
