import * as React from "react";
import { observer } from "mobx-react";
import { EGDSCard, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSScrim, EGDSScrimType } from "@egds/react-core/scrim";
import { EGDSLink } from "@egds/react-core/link";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { DestinationOfferPriceInfo } from "../DestinationOfferPriceInfo/DestinationOfferPriceInfo";
import { destinationOfferItem } from "../../Common/MerchCampaignCard/MerchCampaignCard";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { OfferItem } from "../../types";

export interface FullBleedCardProps {
  offer: OfferItem;
  productType: string;
  cardIndex: number;
  campaignId: string;
  displayDate: boolean;
  displayPrice: boolean;
  moduleName: string;
  border?: boolean;
}

export const FullBleedCard = observer(
  ({
    offer,
    productType,
    cardIndex,
    campaignId,
    displayDate,
    displayPrice,
    moduleName,
    border,
  }: FullBleedCardProps) => {
    if (offer.__typename !== "DestinationOfferItem") {
      return null;
    }

    const offerItem = destinationOfferItem(productType, offer);

    if (offerItem === null || offerItem === undefined) {
      return null;
    }

    return (
      <>
        <EGDSCard className="FullBleedCard" border={border}>
          <EGDSFigure ratio={EGDSFigureAspectRatioType.R3_2}>
            <EGDSImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
            <EGDSScrim type={EGDSScrimType.BOTTOM}>
              <EGDSLayoutFlex justifyContent="space-between">
                <>
                  <EGDSLayoutFlexItem>
                    <div>
                      <EGDSHeading tag="h3" size={5}>
                        {offer.name}
                      </EGDSHeading>
                      {displayDate && <EGDSText size={300}>{offer.dates}</EGDSText>}
                      <EGDSText size={300}>{offer.secondaryContent}</EGDSText>
                    </div>
                  </EGDSLayoutFlexItem>
                  <EGDSLayoutFlexItem>
                    <div>
                      {displayPrice && offerItem.price.value && (
                        <DestinationOfferPriceInfo
                          scrimDisplay
                          offerPrice={offerItem.price}
                          cardIndex={cardIndex}
                          campaignId={campaignId}
                        />
                      )}
                      {displayPrice && offerItem.price.checkPriceText && (
                        <div className="uitk-scrim">
                          <EGDSLink align="right">
                            <EGDSText weight="bold">
                              <a className="cardContent" href={offerItem.actionLink.href}>
                                {offerItem.price.checkPriceText}
                              </a>
                            </EGDSText>
                          </EGDSLink>
                        </div>
                      )}
                    </div>
                  </EGDSLayoutFlexItem>
                </>
              </EGDSLayoutFlex>
            </EGDSScrim>
          </EGDSFigure>
          <EGDSCardLink>
            <TrackedLink
              moduleName={moduleName}
              action={Action.CLICK}
              href={offerItem.actionLink.href}
              rfrr={`card-${campaignId}-${cardIndex}`}
            >
              {offerItem.actionLink.text}
            </TrackedLink>
          </EGDSCardLink>
        </EGDSCard>
      </>
    );
  }
);
