import * as React from "react";
import { observer } from "mobx-react";
import { EGDSCard, EGDSCardLink, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSVipBadge } from "@egds/react-core/badge";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText, EGDSHeading, EGDSParagraph } from "@egds/react-core/text";
import { EGDSLink } from "@egds/react-core/link";
import { OfferItem } from "../../types";

export interface PackageInventoryCardProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  border?: boolean;
}

export const PackageInventoryCard = observer(
  ({ offer, cardIndex, campaignId, moduleName, border }: PackageInventoryCardProps) => {
    if (offer.__typename !== "PackageOfferItem") {
      return null;
    }

    const { hotelOfferItem, flightOfferItem, packageOffer } = offer;
    const { formatText } = useLocalization();

    return (
      <EGDSCard className="PackageInventoryCard" border={border}>
        <EGDSFigure ratio={EGDSFigureAspectRatioType.R21_9}>
          {hotelOfferItem?.vipMessage && (
            <EGDSSpacing margin={{ blockstart: "three", inlinestart: "three" }}>
              <EGDSVipBadge className="hotelBadge" text={hotelOfferItem.vipMessage} />
            </EGDSSpacing>
          )}
          <EGDSImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
        </EGDSFigure>
        <div className="uitk-card-content">
          <EGDSCardContentSection>
            <EGDSParagraph size={2}>{offer.description}</EGDSParagraph>
            <EGDSSpacing padding={{ blockend: "half" }}>
              <EGDSHeading tag="h3" size={5} overflow="truncate">
                {hotelOfferItem?.name}
              </EGDSHeading>
            </EGDSSpacing>
            <EGDSText size={300}>{hotelOfferItem?.location}</EGDSText>
            <EGDSSpacing padding={{ blockstart: "three" }}>
              <div>
                <EGDSSpacing margin={{ inlineend: "one" }}>
                  <img
                    className="airlinesIcon"
                    src={flightOfferItem?.airlineImage?.url}
                    alt={flightOfferItem?.airlineImage?.description}
                  />
                </EGDSSpacing>
                <EGDSText size={200} inline>
                  {flightOfferItem?.airlineName}
                </EGDSText>
              </div>
            </EGDSSpacing>
            {flightOfferItem && (
              <EGDSSpacing padding={{ blockend: "one" }}>
                <EGDSText size={300}>
                  {formatText(
                    "merchCampaign.packageOffer.flightOffer.name",
                    flightOfferItem.originCity,
                    flightOfferItem.originAirportCode,
                    flightOfferItem.destinationCity,
                    flightOfferItem.destinationAirportCode
                  )}
                </EGDSText>
              </EGDSSpacing>
            )}
          </EGDSCardContentSection>
          <EGDSCardContentSection border="top">
            <EGDSLayoutFlex justifyContent="space-between">
              <>
                <EGDSText size={200}>{offer.dates}</EGDSText>
                {packageOffer?.price.value && (
                  <div>
                    <EGDSSpacing padding={{ inlineend: "one" }}>
                      <EGDSText size={100} inline align="right" className="price-prefix">
                        {packageOffer.price.prefixText}
                      </EGDSText>
                    </EGDSSpacing>
                    <EGDSText size={600} weight="medium" theme="emphasis" align="right" inline>
                      {packageOffer.price.value}
                    </EGDSText>
                    <EGDSText size={100} align="right">
                      {packageOffer.price.qualifierText}
                    </EGDSText>
                  </div>
                )}
                {packageOffer?.price.checkPriceText && (
                  <div>
                    <EGDSLink align="right">
                      <EGDSText weight="bold">
                        <a href={packageOffer?.actionLink.href} className="price-prefix">
                          {packageOffer?.price.checkPriceText}
                        </a>
                      </EGDSText>
                    </EGDSLink>
                  </div>
                )}
              </>
            </EGDSLayoutFlex>
          </EGDSCardContentSection>
        </div>
        <EGDSCardLink>
          <TrackedLink
            moduleName={moduleName}
            action={Action.CLICK}
            href={packageOffer?.actionLink.href}
            rfrr={`card-${campaignId}-${cardIndex}`}
          >
            {packageOffer?.actionLink.text}
          </TrackedLink>
        </EGDSCardLink>
      </EGDSCard>
    );
  }
);
