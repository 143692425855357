import * as React from "react";
import { EGDSDialog, EGDSDialogContent, EGDSDialogFooter } from "@egds/react-core/dialog";
import { EGDSParagraph } from "@egds/react-core/text";
import { EGDSToolbar } from "@egds/react-core/toolbar";
import { useLocalization } from "@shared-ui/localization-context";

interface InfoDialogProps {
  buttonText: string;
  closeDialog: () => void;
  infoText?: string;
  isDialogOpen?: boolean;
  /* show or hide toolbar */
  hideToolbar?: boolean;
}

export const InfoDialog: React.FC<InfoDialogProps> = ({
  infoText,
  closeDialog,
  buttonText,
  isDialogOpen,
  hideToolbar,
}) => {
  const { formatText } = useLocalization();

  return (
    <EGDSDialog dialogShow={isDialogOpen} returnFocusOnClose={true}>
      {!hideToolbar ? (
        <EGDSToolbar
          navigationContent={{
            navIconLabel: formatText("DestinationsByAffinity.priceDialogCloseButton"),
            onClick: closeDialog,
          }}
        />
      ) : null}
      <EGDSDialogContent>
        {infoText?.split("\n").map((value, index) => (
          <EGDSParagraph key={index}>{value}</EGDSParagraph>
        ))}
        <EGDSDialogFooter
          buttonOne={{
            onClick: closeDialog,
            text: buttonText,
            type: "tertiary",
          }}
        />
      </EGDSDialogContent>
    </EGDSDialog>
  );
};
