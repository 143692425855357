import * as React from "react";
import { observer } from "mobx-react";
import { EGDSCard, EGDSCardLink, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { DestinationOfferPriceInfo } from "../DestinationOfferPriceInfo/DestinationOfferPriceInfo";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { destinationOfferItem } from "../../Common/MerchCampaignCard/MerchCampaignCard";
import { OfferItem } from "../../types";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSLink } from "@egds/react-core/link";

export interface ImageTopCardProps {
  offer: OfferItem;
  productType: string;
  cardIndex: number;
  campaignId: string;
  displayDate: boolean;
  displayDescription: boolean;
  displayPrice: boolean;
  moduleName: string;
  border?: boolean;
}

export const ImageTopCard = observer(
  ({
    offer,
    productType,
    cardIndex,
    campaignId,
    displayDate,
    displayDescription,
    displayPrice,
    moduleName,
    border,
  }: ImageTopCardProps) => {
    if (offer.__typename !== "DestinationOfferItem") {
      return null;
    }

    const offerItem = destinationOfferItem(productType, offer);

    if (offerItem === null || offerItem === undefined) {
      return null;
    }

    return (
      <>
        <EGDSCard border={border}>
          <EGDSFigure ratio={EGDSFigureAspectRatioType.R21_9}>
            <EGDSImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
          </EGDSFigure>
          <EGDSCardLink>
            <TrackedLink
              moduleName={moduleName}
              action={Action.CLICK}
              href={offerItem.actionLink.href}
              rfrr={`card-${campaignId}-${cardIndex}`}
            >
              {offerItem.actionLink.text}
            </TrackedLink>
          </EGDSCardLink>
          <div className="uitk-card-content">
            <EGDSCardContentSection>
              <EGDSLayoutFlex justifyContent="space-between">
                <>
                  <EGDSLayoutFlexItem>
                    <div>
                      <EGDSHeading tag="h3" size={5}>
                        {offer.name}
                      </EGDSHeading>
                      {displayDate && (
                        <EGDSText size={300} weight="regular">
                          {offer.dates}
                        </EGDSText>
                      )}
                      <EGDSText size={300} weight="regular">
                        {offer.secondaryContent}
                      </EGDSText>
                    </div>
                  </EGDSLayoutFlexItem>
                  <EGDSLayoutFlexItem>
                    <EGDSText align="right">
                      {displayPrice && offerItem?.price.value && (
                        <DestinationOfferPriceInfo
                          scrimDisplay={false}
                          offerPrice={offerItem.price}
                          cardIndex={cardIndex}
                          campaignId={campaignId}
                        />
                      )}
                      {displayPrice && offerItem.price.checkPriceText && (
                        <EGDSLink>
                          <EGDSText weight="bold">
                            <a href={offerItem.actionLink.href}>{offerItem.price.checkPriceText}</a>
                          </EGDSText>
                        </EGDSLink>
                      )}
                    </EGDSText>
                  </EGDSLayoutFlexItem>
                </>
              </EGDSLayoutFlex>
            </EGDSCardContentSection>
            {displayDescription && offer.description && (
              <EGDSCardContentSection border="top">
                {offer.description && (
                  <EGDSText size={300} weight="regular">
                    {offer.description}
                  </EGDSText>
                )}
              </EGDSCardContentSection>
            )}
          </div>
        </EGDSCard>
      </>
    );
  }
);
