import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { observer } from "mobx-react";
import { EGDSCard, EGDSCardLink, EGDSCardContentSection } from "@egds/react-core/cards";
import { locKeys } from "components/shared/Merchandising/Inventory/l10n";
import { useLocalization } from "@shared-ui/localization-context";
import { withStores } from "stores";
import { InfoDialog } from "../../Common/InfoDialog/InfoDialog";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";
import { EGDSSpacing, EGDSSpacingHr } from "@egds/react-core/spacing";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSBadge, EGDSDealMemberBadge, EGDSVipBadge } from "@egds/react-core/badge";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSMark, EGDSMarkSize } from "@egds/react-core/mark";
import { EGDSLayoutGrid } from "@egds/react-core/layout-grid";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSheetTransition } from "@egds/react-core/sheet";
import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";
import { OfferItem } from "../../types";

interface MarkBrandMapType {
  expedia: string;
  lastminute: string | undefined;
  orbitz: string;
  travelocity: string;
  wotif: string;
  cheaptickets: string;
  ebookers: string;
}

const MarkBrandMap: MarkBrandMapType = {
  expedia: "mod_exp",
  lastminute: undefined,
  orbitz: "mod_orb",
  travelocity: "mod_tvly",
  wotif: "mod_wotif",
  cheaptickets: "mod_ctx",
  ebookers: "mod_ebook",
};

const defaultUndefinedMark = "lastminute";

export interface HotelInventoryCardProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  border?: boolean;
  context?: ExtendedContextStore;
}

export const HotelInventoryCard = ({ offer, cardIndex, campaignId, moduleName, border }: HotelInventoryCardProps) => {
  if (offer.__typename !== "HotelOfferItem") {
    return null;
  }

  return (
    <EGDSCard className="HotelInventoryCard" border={border} data-testid="HotelInventoryCard">
      <EGDSFigure ratio={EGDSFigureAspectRatioType.R21_9}>
        <EGDSSpacing margin={{ blockstart: "three" }}>
          <EGDSLayoutPosition type="absolute" position={{ left: "three", zindex: "layer2" }} cloneElement>
            <EGDSLayoutFlex space="two">
              <ImageBadges offer={offer} />
            </EGDSLayoutFlex>
          </EGDSLayoutPosition>
        </EGDSSpacing>
        <EGDSImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
      </EGDSFigure>
      <EGDSCardContentSection className="HotelInventoryCardSection">
        <EGDSSpacing padding={{ blockend: "four" }}>
          <EGDSLayoutPosition type="relative">
            <div>
              <EGDSSpacing padding={{ blockend: "half" }}>
                <EGDSHeading overflow="truncate" tag="h3" size={5}>
                  {offer.name}
                </EGDSHeading>
              </EGDSSpacing>
              <EGDSText size={300}>{offer.location}</EGDSText>
              {offer.guestReviewRating && (
                <EGDSLayoutPosition type="absolute" position={{ bottom: "minus-two" }} cloneElement>
                  <div className="reviewRating">
                    <EGDSLayoutFlex>
                      <EGDSSpacing padding={{ inlineend: "one" }}>
                        <EGDSText weight="bold" size={300}>
                          {offer.guestReviewRating}
                        </EGDSText>
                      </EGDSSpacing>
                      <EGDSText size={300} className="pwa-theme--grey-700" overflow="truncate">
                        {offer.guestReviewCount}
                      </EGDSText>
                    </EGDSLayoutFlex>
                  </div>
                </EGDSLayoutPosition>
              )}
            </div>
          </EGDSLayoutPosition>
        </EGDSSpacing>
      </EGDSCardContentSection>
      <EGDSLayoutPosition type="relative">
        <EGDSCardContentSection className="HotelInventoryCardSection">
          <Separator offer={offer} />
          {offer.freeCancellationMessage && (
            <EGDSLayoutPosition type="absolute" position={{ top: "six" }} cloneElement>
              <EGDSText size={300} weight="bold" theme="positive" spacing="two">
                {offer?.freeCancellationMessage}
              </EGDSText>
            </EGDSLayoutPosition>
          )}
          <EGDSLayoutGrid alignItems="end" columns={2} space="two">
            <>
              <EGDSText align="left" size={200}>
                {offer?.dates}
              </EGDSText>
              <EGDSText align="right">
                <PriceComponent offer={offer} cardIndex={cardIndex} campaignId={campaignId} moduleName={moduleName} />
              </EGDSText>
            </>
          </EGDSLayoutGrid>
        </EGDSCardContentSection>
      </EGDSLayoutPosition>
      <EGDSCardLink>
        {offer.lodgingOffer && (
          <TrackedLink
            moduleName={moduleName}
            action={Action.CLICK}
            href={offer.lodgingOffer?.actionLink.href}
            rfrr={`card-${campaignId}-${cardIndex}`}
          >
            {offer.name}
          </TrackedLink>
        )}
      </EGDSCardLink>
    </EGDSCard>
  );
};

interface MarkIconProps {
  context: ExtendedContextStore;
}

type OfferProps = Pick<HotelInventoryCardProps, "offer">;

const MarkIcon = withStores("context")(
  observer(({ context }: MarkIconProps) => {
    const markName = MarkBrandMap[(context.site?.brand as keyof MarkBrandMapType) ?? defaultUndefinedMark];
    if (markName) {
      return <EGDSMark name={markName} size={EGDSMarkSize.SMALL} />;
    }
    return <EGDSIcon name="mod" size={EGDSIconSize.SMALL} />;
  })
);

const ImageBadges: React.FC<OfferProps> = ({ offer }) => {
  if (offer.__typename !== "HotelOfferItem") {
    return null;
  }

  if (offer?.vipMessage && offer?.mod?.modTagText) {
    return (
      <>
        <EGDSLayoutFlexItem>
          <EGDSDealMemberBadge>
            <MarkIcon />
          </EGDSDealMemberBadge>
        </EGDSLayoutFlexItem>
        <EGDSLayoutFlexItem>
          <EGDSVipBadge text={offer.vipMessage} />
        </EGDSLayoutFlexItem>
      </>
    );
  }

  if (offer?.vipMessage) {
    return <EGDSVipBadge text={offer.vipMessage} />;
  }

  if (offer?.mod?.modTagText) {
    return (
      <EGDSLayoutFlexItem>
        <EGDSDealMemberBadge text={offer.mod.modTagText}>
          <MarkIcon />
        </EGDSDealMemberBadge>
      </EGDSLayoutFlexItem>
    );
  }

  return null;
};

const SeperatorBadge = ({ modText, badgeText }: { modText?: string | null; badgeText?: string }) => {
  if (modText) {
    return <EGDSDealMemberBadge text={badgeText} />;
  }
  return <EGDSBadge text={badgeText} />;
};

const Separator: React.FC<OfferProps> = ({ offer }) => {
  if (offer.__typename !== "HotelOfferItem") {
    return null;
  }

  const badgeText = offer.discountBadge?.text;
  const badgeClass = badgeText ? "separatorLineWidth" : "";

  if (offer?.mod?.modAvailabilityText) {
    return (
      <>
        <div className={badgeClass}>
          <EGDSSpacingHr blockMargin="one" />
        </div>
        <EGDSLayoutPosition type="relative" position={{ top: "minus-four" }} cloneElement>
          <EGDSText align="right">
            <EGDSDealMemberBadge text={offer?.mod?.modAvailabilityText} />
          </EGDSText>
        </EGDSLayoutPosition>
      </>
    );
  }
  if (badgeText) {
    return (
      <>
        <div className={badgeClass}>
          <EGDSSpacingHr blockMargin="one" />
        </div>
        <EGDSLayoutPosition type="relative" position={{ top: "minus-four" }} cloneElement>
          <EGDSText align="right">
            <SeperatorBadge modText={offer.mod?.modTagText} badgeText={badgeText} />
          </EGDSText>
        </EGDSLayoutPosition>
      </>
    );
  }
  return (
    <EGDSSpacing padding={{ blockend: "six" }}>
      <div>
        <EGDSSpacingHr blockMargin="one" />
      </div>
    </EGDSSpacing>
  );
};

interface PriceComponentProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  cardClicked?: () => void;
}

const PriceComponent = observer(({ offer, cardIndex, campaignId, moduleName, cardClicked }: PriceComponentProps) => {
  const disclaimerRef = React.useRef<HTMLButtonElement>(null);

  if (offer.__typename !== "HotelOfferItem") {
    return null;
  }

  const { formatText } = useLocalization();
  const [isDialogOpen, dialogActions, PriceDialogComponent] = useDialog(`disclaimer-info-${campaignId}-${cardIndex}`);

  if (offer?.mod?.modSignInText) {
    return (
      <TrackedLink
        moduleName={moduleName}
        action={Action.CLICK}
        rfrr={`card-${campaignId}-${cardIndex}-MOD-SignUp`}
        onClick={cardClicked}
        href={offer?.mod?.signInUrl?.relativePath}
      >
        <EGDSText size={300} weight="bold" align="right">
          {offer?.mod?.modSignInText}
        </EGDSText>
      </TrackedLink>
    );
  }

  const closeDialog = () => {
    dialogActions.closeDialog();

    /* istanbul ignore next */
    if (disclaimerRef?.current) {
      disclaimerRef.current.focus();
    }
  };

  return (
    <>
      <PriceLockup
        lockupPrice={offer.lodgingOffer?.price?.value}
        priceSubtextFirst={offer.lodgingOffer?.price?.qualifierText}
        a11yPrice={offer.lodgingOffer?.price?.allyText || ""}
        a11yStrikeThroughDialogTrigger={formatText(locKeys.priceDisclaimerLabel)}
        strikeThroughPrice={offer?.lodgingOffer?.strikeThroughPrice?.value}
        hasMoreInfoTrigger
        onClickMoreInfoTrigger={dialogActions.openDialog}
        disclaimerRef={disclaimerRef}
      />
      <EGDSSheetTransition isVisible={isDialogOpen}>
        <PriceDialogComponent>
          <InfoDialog
            infoText={offer?.lodgingOffer?.price.disclaimerText}
            isDialogOpen={isDialogOpen}
            closeDialog={closeDialog}
            buttonText={formatText("DestinationsByAffinity.priceDialogCloseButton")}
          />
        </PriceDialogComponent>
      </EGDSSheetTransition>
    </>
  );
});
